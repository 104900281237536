import request from '@/utils/request'


// 查询banner列表
export function listBanner(query) {
  return request({
    url: '/advert/banner/list',
    method: 'get',
    params: query
  })
}

// 查询banner分页
export function pageBanner(query) {
  return request({
    url: '/advert/banner/page',
    method: 'get',
    params: query
  })
}

export function bannerPosition(query) {
  return request({
    url: '/advert/banner/type/list',
    method: 'get',
    params: query
  })
}

// 查询banner详细
export function getBanner(data) {
  return request({
    url: '/advert/banner/detail',
    method: 'get',
    params: data
  })
}

// 新增banner
export function addBanner(data) {
  return request({
    url: '/advert/banner/add',
    method: 'post',
    data: data
  })
}

// 修改banner
export function updateBanner(data) {
  return request({
    url: '/advert/banner/edit',
    method: 'post',
    data: data
  })
}

// 删除banner
export function delBanner(data) {
  return request({
    url: '/advert/banner/delete',
    method: 'post',
    data: data
  })
}
