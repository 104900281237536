<template>
  <a-modal width="60%" :label-col="4" :wrapper-col="14" :visible="open" @ok="onOk" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>选择活动</b>
    </a-divider>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">

            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('活动记录.活动编号')" prop="id">
                <a-input v-model="queryParam.id" :placeholder="$t('通用.输入.请输入')+$t('活动记录.活动编号')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('活动记录.活动名称')" prop="activityName">
                <a-input v-model="queryParam.activityName" :placeholder="$t('通用.输入.请输入')+$t('活动记录.活动名称')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('活动记录.开始时间')" prop="startTime">
                <a-date-picker style="width: 100%" v-model="queryParam.startTime" valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('活动记录.结束时间')" prop="endTime">
                <a-date-picker style="width: 100%" v-model="queryParam.endTime" valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('活动记录.状态')" prop="status">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('活动记录.状态')" style="width: 100%" v-model="queryParam.status" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.ActivitySaleStatusEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, type: 'radio', onChange: onSelectChange}"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="activityType" slot-scope="text, record">
              {{getCategoryNameById(record.activityType)}}
        </span>
        <span slot="status" slot-scope="text, record">
           <custom-dict-tag :options="customDict.ActivitySaleStatusEnum" :value="record.status"/>
        </span>
        <template slot="coverPic" slot-scope="text, record">
          <div>
            <img v-if="record.coverPic" :src="record.coverPic"  style="width:60px;height:62px;" @click="handleRecordPreview(record.coverPic)" alt="none"/>
          </div>
        </template>
        <template slot="posterPic" slot-scope="text, record">
          <div>
            <img v-if="record.posterPic" :src="record.posterPic"  style="width:60px;height:62px;" @click="handleRecordPreview(record.posterPic)" alt="none"/>
          </div>
        </template>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </a-modal>
</template>

<script>
import {pageGoods} from '@/api/goods/goods'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {listCategory} from "@/api/goods/category";
import Editor from "@/components/Editor/Editor";
import { tableMixin } from '@/store/table-mixin'
import {allBrand} from "@/api/goods/brand";
import {pageActivity} from "@/api/activity/activity";
import {listType} from "@/api/activity/type";


export default {
  name: 'RadioActivitySelectForm',
  props: {},
  components: {
    Editor,
    CustomDictTag,
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      activityTypeList:[],
      pageSizeOptions: ['1', '20', '50', '100'],
      selectedRowKeys: [],
      selectedRows: [],
      categoryList: [],
      cateOptions: [],
      brandList: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      open: false,
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        activityType: null,
        activityName: null,
        startTime: null,
        orderByActivityId:undefined,
        endTime: null,
        coverPic: null,
        posterPic: null,
        posterTxt: null,
        levelIds: null,
        limitNum: null,
        description: null,
        status: null,
        sort: null,
        area: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('活动记录.活动编号'),
          dataIndex: 'id',
          align: 'center'
        },
        {
          title: this.$t('活动记录.活动类型'),
          dataIndex: 'activityType',
          scopedSlots: { customRender: 'activityType' },
          align: 'center'
        },
        {
          title: this.$t('活动记录.活动名称'),
          dataIndex: 'activityName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('活动记录.开始时间'),
          dataIndex: 'startTime',
          scopedSlots: { customRender: 'startTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('活动记录.结束时间'),
          dataIndex: 'endTime',
          scopedSlots: { customRender: 'endTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('活动记录.活动封面图'),
          dataIndex: 'coverPic',
          scopedSlots: {customRender: 'coverPic'},
          align: 'center'
        },
        /*{
          title: this.$t('活动记录.分享海报图'),
          dataIndex: 'posterPic',
          scopedSlots: {customRender: 'posterPic'},
          align: 'center'
        },*/
        /*{
          title: this.$t('活动记录.最多参与次数'),
          dataIndex: 'limitNum',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: this.$t('活动记录.状态'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center'
        },
        /*{
          title: this.$t('通用.文本.排序'),
          dataIndex: 'sort',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: this.$t('活动记录.创建时间'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
      ]
    };
  },
  filters: {},
  created() {
    this.getAtvCateList();
  },
  computed: {
    ...mapGetters(['customDict']),
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
      this.resetQuery()
    },
    getAtvCateList() {
      listType().then(response => {
        this.activityTypeList = response.data;
      })
    },
    getCategoryNameById(id) {
      if (!id) {
        return "";
      }
      let cateInfo = this.activityTypeList.find(e => e.id === id);
      if (cateInfo) {
        return cateInfo.name
      } else {
        return "";
      }
    },
    /**
     *
     * @param starId 明星id ,如果选择了
     * @param records 会先过来的id
     */
    openAtv(records) {
      if (records) {
        console.log('带过来的key',records)
        this.selectedRowKeys = records;
        this.queryParam.orderByActivityId=records[0]
      }
      this.getList()
      this.open = true;
    },
    onOk() {
      //判断有没有选择,拿到列表里选择的
      this.open = false
      this.$emit('atvSelect', this.selectedRows)
      this.resetQuery()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.resetQuery()
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询明星列表 */
    getList () {
      this.loading = true
      pageActivity(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.selectedRowKeys= [];
      this.selectedRows= [];
      this.queryParam = {
        activityType: undefined,
        activityName: undefined,
        orderByActivityId:undefined,
        startTime: undefined,
        endTime: undefined,
        coverPic: undefined,
        posterPic: undefined,
        posterTxt: undefined,
        levelIds: undefined,
        limitNum: undefined,
        description: undefined,
        status: undefined,
        sort: undefined,
        area: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
  }
}
</script>
